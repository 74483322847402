<template>
  <div>
    <v-app-bar :elevation="0">
      <v-app-bar-nav-icon icon="mdi:mdi-chevron-left" @click="handleGoBack()" />
      <v-app-bar-title>
        <template v-if="sale.operation == 'sale'">
          <span class="text-capitalize">Venda</span>
        </template>
        <template v-else>
          <span class="text-capitalize text-error">Devolução</span>
        </template>
        <!-- <DrawerStatusChip class="ml-2" :status="isOpen" /> -->
      </v-app-bar-title>
      <template v-slot:append>
        <template v-if="!$vuetify.display.mobile">
          <div class="d-flex align-center ga-2">
            <div v-if="$acl.can('viewAllSales')">
              <AppBtn @click="handleSearchOrder()" color="primary">
                <AppIcon name=hugeicons:search-01 size="16" class="mr-2" />
                Pedidos
              </AppBtn>
            </div>
            <div style="width:250px">
              <SalesmanSearch :readonly="canSelectSalesman" v-model="sale.salesman" />
            </div>
          </div>
        </template>
      </template>
    </v-app-bar>
    <v-main>
      <!-- <FreeTrialAlert /> -->
      <slot />
    </v-main>
    <template v-if="$vuetify.display.mobile">
      <div id="appBottomBar">

      </div>
    </template>
  </div>
  <SearchOrderDialog ref="searchOrderDialog" @select="loadOrder" />
</template>

<script setup>

const searchOrderDialog = ref(null);

const router = useRouter();

const $acl = useAcl();

const $loading = useLoading();

const route = useRoute();

const saleFormStore = useSaleFormStore();

const { sale } = storeToRefs(saleFormStore)

const drawerStore = useDrawerStore();

const { isOpen } = storeToRefs(drawerStore);

const canSelectSalesman = computed(() => !(!sale.value?.salesman_id && !sale.value.id))

const handleSearchOrder = () => {
  searchOrderDialog.value.open()
}

const loadOrder = async (sale_id) => {
  saleFormStore.$reset()

  $loading.start();
  await saleFormStore.loadSale(sale_id)
    .then((response) => $loading.stop())
    .catch((error) => $loading.stop());

}

const handleGoBack = () => {
  if (route.meta.backTo) {
    router.push(route.meta.backTo)
  }
  else {
    router.back()
  }
}

</script>
